export default {
    id: null,
    name: null,
    code: null,
    entityName: null,
    entityId: null,
    category: null,
    description: null,
    stylesheet: '/* Somente preencha se tiver conhecimento em CSS e na documentação do software */',
    config: {},
    order: 100,
    active: true
}
